<template lang="pug">
  div.wrap-module-work-check-payment.fc.f
    div.order-check-payment
      div.order-check-payment-container.f.fh
        p.text-center.bold(v-if="isShowLoading") お支払い情報の確認中...
          MinNowLoading(:show="isShowLoading")
      div.order-check-payment-steps
        img(src="@/assets/walker.png")
    v-dialog(v-model="isShowDialog" overlay-color="#000000" overlay-opacity="0.6" persistent)
      v-card.pa-4
        div
          p.fz18.bold.mt-2.text-center(v-if="isPaid && !hasError") お支払いが確認できました！
          p.fz16.bold.mt-6.mb-0.text-center(v-if="isPaid && !hasError") ご利用ありがとうございました！
          p.fz16.bold.mt-0.mb-5.text-center(v-if="isPaid && !hasError") 今後もお気軽にご利用ください。
          p.fz18.bold.mt-2.text-center(v-if="!isPaid && !hasError") お支払いが確認できませんでした
          p.fz16.bold.mt-6.mb-5.text-center(v-if="!isPaid && !hasError") メッセージのボタンから決済を完了させてください。
          p.fz18.bold.mt-2.text-center(v-if="hasError") エラーが発生しました
          p.fz16.bold.mt-6.mb-5.text-center(v-if="hasError") お手数ですがこのページを更新お願いします。
          div.f.fc.ma-2(v-if="!hasError && isPaid")
            v-btn(color="#E7305B")
              span.bold(style="color:#fff;" @click="toMypage") マイページへ
          div.f.fc.ma-2(v-if="!hasError && !isPaid")
            v-btn(color="#E7305B")
              span.bold(style="color:#fff;" @click="toMessage") メッセージへ

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap');

.wrap-module-work-check-payment {
  margin: 0 auto;
  width: 100%;
  max-width: $base_max_width_px;
  min-height: 100vh;

  .order-check-payment {
    width: 100%;
    max-width: $base_max_width_px;

    .order-check-payment-container {
      height: calc(100% - 150px);
    }
  }

  .order-check-payment-steps {
    position: fixed;
    width: 100%;
    bottom: 0px;
    max-width: $base_max_width_px;
    overflow: hidden;
    background-image: url("/img/city.png");
    background-size: cover;

    img {
      position: relative;
      left: calc(70% + 30px);
      height: 120px;
    }
  }
}

.alert {
  color: $text_accent;
}

.input-card {
  min-width: 260px;
  padding-top: 32px;
  height: 180px
}

.input-time {
  margin-left: 10% !important;
  margin-right: 10% !important;
}

</style>

<script>
import { functions } from '@/components/utils/firebase'
import { database } from '../../../functions/shared/database'
import { createNamespacedHelpers } from 'vuex'
import MinNowLoading from '@/components/shared/MinNowLoading'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    MinNowLoading
  },
  data () {
    return {
      isShowLoading: true,
      isPaid: false,
      hasError: false,
      isShowDialog: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async mounted () {
    const orders = await database.orderCollection().getOrderByWorkId(this.$route.params.workId)
    const order = orders[0]

    const req = {
      orderId: order.id,
      type: 'advancedPayment',
      merchantPaymentId: this.$route.params.workId

    }

    // const getPaymentDetails = functions.httpsCallable('getPaymentDetails')
    // getPaymentDetails({ req }).then((res) => {
    //   this.isShowLoading = false
    //   this.isShowDialog = true
    //   console.log(res)
    //   const status = res.data.status
    //   if (status === 200) {
    //     const result = res.data.body.data
    //     if (result.status === 'COMPLETED') {
    //       this.isPaid = true
    //     }
    //   } else {
    //     this.hasError = true
    //   }
    // })
  },
  methods: {
    toMypage () {
      this.$router.push('/mypage')
    },
    toMessage () {
      this.$router.push(`/mypage/order/${this.$route.params.workId}`)
    }
  }
}
</script>

<template lang="pug">
  div
    Header(:content="header")
    ModalPage(
      v-for="(item, index) in modalPages"
      :key="index"
      :showModalPage="item.show"
      :index="index")
      ModuleMyOrder(
      v-if="modalPages[index].currentContent == 'myorder' && index == 0"
      @closeModalPage="modalPages[index].show = false")
      ModuleCheckWorkPayment(
      v-if="modalPages[index].currentContent == 'checkWorkPayment' && index == 1"
      @closeModalPage="modalPages[index].show = false")
      ModuleOrderReview(
      v-if="modalPages[index].currentContent == 'orderReview' && index == 2"
      @closeModalPage="modalPages[index].show = false")
    ModalWindow(
      :showModal="showModalWindow"
      @closeModalWindow="closeModalWindow")
      ModuleTmp(v-if="modalWindowContent == 'tmp'")
    slot
</template>

<script>
// import { database } from '@shared/database'
import { createNamespacedHelpers } from 'vuex'

import Header from '@/components/shared/Header'
import ModalWindow from '@/components/shared/ModalWindow'
import ModalPage from '@/components/shared/ModalPage'
import ModuleMyOrder from '@/components/module/ModuleMyOrder'
import ModuleCheckWorkPayment from '@/components/module/ModuleCheckWorkPayment'
import ModuleOrderReview from '@/components/module/ModuleOrderReview'

const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Header,
    ModalPage,
    ModalWindow,
    ModuleMyOrder,
    ModuleCheckWorkPayment,
    ModuleOrderReview
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  props: {
    rootHeader: {},
    opt: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      header: {},
      modalPages: [
        { currentContent: '', show: false },
        { currentContent: '', show: false },
        { currentContent: '', show: false }
      ],
      showModalWindow: false,
      modalWindowContent: ''
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) {
        this.changeModalPageByRouteParams()
      }
    },
    rootHeaderTitle: function () {
      this.rootHeader.title = this.rootHeaderTitle
    }
  },
  created () {
    this.changeModalPageByRouteParams()
  },
  methods: {
    async changeModalPageByRouteParams () {
      console.log('changeModalPageByRouteParams:', this.$route.name)

      window.scrollTo(0, 0)

      this.header = {}
      this.modalPageContent = ''

      let currentModalIndex
      switch (this.$route.name) {
        case 'tmp':
        case 'mypage':
        case 'myworks':
          currentModalIndex = -1
          this.header = this.rootHeader
          break

        case 'settings':
          currentModalIndex = 0
          this.modalPages[currentModalIndex].currentContent = 'settings'
          this.modalPages[currentModalIndex].show = true

          this.header = {
            title: '設定',
            left: {
              label: 'マイページ',
              icon: 'mdi-chevron-left',
              to: '/mypage'
            }
          }
          break

        case 'myorder':
          currentModalIndex = 0
          this.modalPages[currentModalIndex].currentContent = 'myorder'
          this.modalPages[currentModalIndex].show = true

          this.header = {
            title: 'メッセージ',
            left: {
              label: 'マイページ',
              icon: 'mdi-chevron-left',
              color: '',
              to: '/mypage/'
            }
          }
          break

        case 'check-advanced-money-payment':
          currentModalIndex = 1
          this.modalPages[currentModalIndex].currentContent = 'checkWorkPayment'
          this.modalPages[currentModalIndex].show = true

          this.header = {
            title: 'お支払いの確認',
            left: {
              label: 'メッセージ',
              icon: 'mdi-chevron-left',
              color: '',
              to: `/mypage/order/${this.$route.params.workId}`
            }
          }
          break

        case 'order-review':
          currentModalIndex = 2
          this.modalPages[currentModalIndex].currentContent = 'orderReview'
          this.modalPages[currentModalIndex].show = true

          this.header = {
            title: 'レビュー',
            left: {
              label: 'メッセージ',
              icon: 'mdi-chevron-left',
              color: '',
              to: `/mypage/order/${this.$route.params.workId}`
            }
          }
          break
      }
      this.modalPages = this.modalPages.map((e, i) => {
        if (i > currentModalIndex) {
          e.currentContent = ''
          e.show = false
        }
        return e
      })
    },
    openModalWindow (content) {
      this.modalWindowContent = content
      this.showModalWindow = true
    },
    closeModalWindow () {
      this.modalWindowContent = ''
      this.showModalWindow = false
    }
  }
}
</script>

<template lang="pug">
  div.f.fc
    div.wrap-module-myorder(v-if="order")
      div.order-title.py-2.f.flex-between
        div
          v-icon.pl-4(color="white") {{ displayCategoryIcon }}
          span.span-left.ml-2.bold.fz18 {{ order.category }}
        div
          span.span-right.mr-3.bold.fz14 {{ '依頼日：' + stringCreatedAt }}
          span.span-right.mx-3.bold.fz14(v-if="stringScheduledDate") {{ '作業日：' + stringScheduledDate }}
      div.message-loader(v-if="isLoading")
      div.message-notification(v-if="hasNewMessage" @click="hideMessageNotification") 新着メッセージがあります
      div#message-container(:style="messageContainerClass")
        div.message(v-for="message in messages" :key="messages.id")
          div.my-message.f.fr.fb(v-if="message.createdBy === uid")
            div.pr-2
              p.timestamp.fz10.bold.mb0(v-if="message.hasRead") 既読
              p.timestamp.fz10.bold.mb0(v-if="!message.hasRead") 未読
              p.timestamp.fz10.bold.mb-2 {{ showCreatedAt(message.createdAt) }}
            div.my-text.mr-6(v-if="message.type=='text'")
              p {{ message.content }}
            div.my-image(v-if="message.type=='image'" style="max-height: 240px; max-width: 240px;")
              img.mr-6(:src="message.content")
          div.other-message.f.fb(v-if="message.createdBy === order.worker")
            div.other-text.ml-6(v-if="message.type=='text'")
              p {{ message.content }}
            div.other-image(v-if="message.type=='image'" style="max-height: 240px; max-width: 240px;")
              img.ml-6(:src="message.content")
            div.pl-2
              p.timestamp.fz10.bold.mb-2 {{ showCreatedAt(message.createdAt) }}
          div.system-message.f.fb(v-if="message.createdBy === 'system'")
            div.system-text.mx-6.my-4(v-if="message.type=='text'")
              p {{ message.content }}
      div.input-container.f.flex-between(:style="inputContainerClass" v-if="order.status != 'done'")
        div.input-left.f.flex-middle(v-if="!isInputting")
          div.ml-2
            v-icon(color="white" @click="selectImage") mdi-image
          div.ml-2.mr-2
            v-icon(color="white" @click="isOpenActions=true" v-if="!isOpenActions") mdi-plus
            v-icon(color="white" @click="isOpenActions=false" v-if="isOpenActions") mdi-close
        div.input-center.ml-4.mr-2
          textarea(v-model="inputText" :style="textareaClass" @focus="isInputting=true" @blur="isInputting=false")
        div.input-right.f.fc.fh(v-if="canSendText")
          div
            v-icon(color="white" @click="sendMessage") mdi-send
      div.actions-container.f.flex-middle(v-if="isOpenActions")
        div.action-content.ml-4.text-center(v-if="!order.candidateDates" @click="showScheduleDialog")
          v-icon(color="white" size="48") mdi-calendar-multiselect
          p.ma-0.bold 予定調整
      input(ref="inputImage" type="file" accept="image/*" @change="setImage" :value="inputImage" style="display: none")
      canvas(id="imgCanvas" ref="imgCanvas" width="0" height="0" style="display: none")
      v-btn.action-btn(v-if="order.status== 'delivered' && !isInputting"
        color="#e7305b" fab
        @click="isShowDialog = true"
        )
        v-icon(color="#ffffff") mdi-checkbox-marked-outline
      v-btn.action-btn(v-if="order.status== 'done' &&  !order.reviewedAt"
        color="#e7305b" fab
        @click="toReview"
        )
        v-icon(color="#ffffff") mdi-pencil
      v-btn.action-btn(v-if="order.reviewedAt"
        color="#f2bcc9" fab depressed)
        v-icon(color="#ffffff") mdi-pencil
    v-dialog(v-model="isShowDialog")
      v-card.dialog-card.pa-4
        div.schedule-container(v-if="dialogType == 'schedule'")
          p.fz18.bold.mt-2 スケジュール調整
          p.fz14.bold.mt-2.ml-2 最大3つまで候補日を指定してください
          div.pa-3
            div.f.flex-middle.mb-2
              p.fz18.bold.mb-0.ml-2 1.
              p.candidate-text.fz18.ml-4.mb-0.bold(v-if="candidateDates.length > 0") {{ displayScheduleDate(candidateDates[0]) }}
              div.flex-middle.ml-4(@click="showDatePicker(0)")
                v-icon(color="black" size="24") mdi-calendar-multiselect
              div.flex-middle.ml-4(@click="popCandidateDates" v-if="candidateDates.length === 1")
                v-icon(color="black" size="24" ) mdi-close
            div.f.flex-middle.mb-2
              p.fz18.bold.mb-0.ml-2 2.
              p.candidate-text.fz18.ml-4.mb-0.bold(v-if="candidateDates.length > 1") {{ displayScheduleDate(candidateDates[1]) }}
              div.flex-middle.ml-4(@click="showDatePicker(1)" v-if="candidateDates.length >= 1")
                v-icon(color="black" size="24") mdi-calendar-multiselect
              div.flex-middle.ml-4(@click="popCandidateDates" v-if="candidateDates.length === 2")
                v-icon(color="black" size="24" ) mdi-close
            div.f.flex-middle.mb-2
              p.fz18.bold.mb-0.ml-2 3.
              p.candidate-text.fz18.ml-4.mb-0.bold(v-if="candidateDates.length > 2") {{ displayScheduleDate(candidateDates[2]) }}
              div.flex-middle.ml-4(@click="showDatePicker(2)" v-if="candidateDates.length >= 2")
                v-icon(color="black" size="24") mdi-calendar-multiselect
              div.flex-middle.ml-4(@click="popCandidateDates" v-if="candidateDates.length === 3")
                v-icon(color="black" size="24" ) mdi-close
            div.f.fh.mt-6
              v-btn(color="#F2F2F2" v-if="candidateDates.length === 0")
                span.bold 候補日を確定
              v-btn(color="#E7305B" v-else @click="decideCandidateDate")
                span.bold(style="color:#fff;") 候補日を確定
        div.schedule-container.text-center(v-if="dialogType == 'datePicker'")
          v-date-picker(v-model="inputDate")
          div.f.fh.mt-4
            v-btn(color="#F2F2F2" v-if="!inputDate")
              span.bold 時間の入力へ
            v-btn(color="#E7305B" v-else @click="decideDate")
              span.bold(style="color:#fff;") 時間の入力へ
        div.schedule-container.text-center(v-if="dialogType == 'timePicker'")
          div.timepicker.f.fh
            select(v-model="selectedHour")
              option(v-for="hour of hours" :value="hour") {{ hour }}
            p.bold.mb-0.mt-1 ：
            select(v-model="selectedMinute")
              option(v-for="minute of minutes" :value="minute") {{ minute }}
          div.f.fh.mt-4
            v-btn(color="#F2F2F2" v-if="!inputDate")
              span.bold 確定
            v-btn(color="#E7305B" v-else @click="decideTime")
              span.bold(style="color:#fff;") 確定
        div.report-container(v-if="dialogType == 'report'")
          p.fz18.bold.mt-2 購入金額の確認
          div.ma-2
            p.fz14.bold.mb-6 購入額：
              span.fz20.bold.ml-4(style="color:#ff5621;") {{ work.totalAmount }}
              span.fz14.bold.ml-1 円
            v-carousel(v-model="imgsIndex" :continuous="false" height="290")
              v-carousel-item(v-for="(img, index) in work.receiptImgs" :key="index")
                div.f.fh
                  img(:src="img" style="max-height: 240px; max-width: 240px;")
            div.f.fh.mt-8.mb-4
              //- v-btn(color="#E7305B" @click="confirmWork")
                //- span.bold(style="color:#fff;") 確認してチップの入力へ
              v-btn(color="#E7305B" @click="toPayment")
                span.bold(style="color:#fff;") 確認してお支払いへ
        div.chip-container.ma-6(v-if="dialogType == 'chip'")
          p.fz18.bold.mt-2 チップの入力
          p.fz14.mb-1.center カモンさんへのチップです。
          p.fz14.center 感謝のお気持ちにご利用ください！
          div.ma-2
            v-text-field(
              v-model="chip"
              type="number"
              placeholder="0"
            )
            div.f.fh.mt-5
              div.ma-2
                v-btn.mb-4(color="#E7305B" @click="confirmChip")
                  span.bold(style="color:#fff;") お支払いへ
        div.mx-8(v-if="dialogType=='selectPaymentMethods'")
          p.fz18.bold.mt-2.text-center お支払い方法の選択
          div.f.fh.mt-8.mb-8
            v-btn(color="#E7305B" width="176" @click="toStripePayment")
              span.bold(style="color:#fff;" width="176") カードでお支払い
          //- div.f.fh.mt-4
          //-   v-btn(color="#E7305B" width="176" @click="toPayment")
          //-     span.bold(style="color:#fff;") PayPayでお支払い
          //- div.f.fh.mt-4.mb-4
          //-   v-btn(color="#E7305B"  width="176" @click="toPayment")
          //-     span.bold(style="color:#fff;") LINEPayでお支払い
        div.stripe-wrapper(v-if="dialogType=='stripePayment'")
          p.fz18.bold.mt-2.text-center ご利用料金のお支払い
          div(v-if="order.agencyFee")
            p.fz14.mr-2.bold.gray.mb-1 合計
            p.text-center(style="font-size: 36px; font-weight: bold;") {{ displayTotalAmount() }}
              span.fz14 円
            div.f.flex-middle
              p.mb2.fz14.ml-4.mr-2 代行費：
              p.mb2(style="font-size: 16px; font-weight: bold") {{order.agencyFee}}
                span.fz12.mt2 円
            div.f.flex-middle
              p.mb2.fz14.ml-4.mr-2 システム利用料：
              p.mb2(style="font-size: 16px; font-weight: bold") {{order.serviceFee}}
                span.fz12.mt2 円
            div.f.flex-middle
              p.mb2.fz14.ml-4.mr-2 買物代金：
              p.mb2(style="font-size: 16px; font-weight: bold") {{order.advancedMoney}}
                span.fz12.mt2 円
            div.f.flex-middle
              p.mb2.fz14.ml-4.mr-2 買物手数料：
              p.mb2(style="font-size: 16px; font-weight: bold") {{displayCommission()}}
                span.fz12.mt2 円
            div.f.flex-middle(v-if="order.servicePaymentCoupon")
              p.mb2.fz14.ml-4.mr-2 クーポン割引：
              p.mb2(style="font-size: 16px; font-weight: bold; color: #ff3259") {{`- ${order.servicePaymentCoupon}`}}
                span.fz12.mt2 円
            div.f.flex-middle(v-if="usedPoint > 0")
              p.mb2.fz14.ml-4.mr-2 ポイント割引：
              p.mb2(style="font-size: 16px; font-weight: bold; color: #ff3259") {{`- ${usedPoint}`}}
                span.fz12.mt2 円
            p.fz14.bold.mb0.mt-2(v-if="point > 0 && !order.payOnlyCash") ポイントを使う
            div.point(v-if="point > 0 && !order.payOnlyCash")
              p.bold.fz14.mb2.ml-4 残り：
                span.bold.fz20.ml-1 {{ point - usedPoint }}
              div.f.fc
                input.mr-4(type="number" v-model="inputPoint")
                div.f.flex-middle.mx-2
                  div(style="width: 24px; height: 24px;")
                    v-icon(color="#02b517" @click="decidePoint") mdi-check-bold
                  div(style="width: 24px; height: 24px;")
                    v-icon.ml-4(color="#f7165a" @click="cancelPoint") mdi-close-thick
          ItemStripeCardElement.mt-4(:order="order" :canPayWithoutStripe="canPayWithoutTransaction" :paymentType="'advancedPayment'" @stripe="afterStripePayment" @no-pay="afterStripePayment")
        div.cash-wrapper(v-if="dialogType=='cashPayment'")
          p.fz18.bold.mt-2.text-center ご利用料金のお支払い
          div
            p.fz14.mr-2.bold.gray.mb-1 合計
            p.text-center(style="font-size: 36px; font-weight: bold;") {{ displayTotalAmount() }}
              span.fz14 円
            div.f.flex-middle
              p.mb2.fz14.ml-4.mr-2 買物代金：
              p.mb2(style="font-size: 16px; font-weight: bold") {{order.advancedMoney}}
                span.fz12.mt2 円
          div.f.fh.mt-8.mb-8
              v-btn(color="#E7305B")
                span.bold(style="color:#fff;" @click="cashPayment") 受け取りを確認
        div(v-if="dialogType=='paypayPayment'")
          p.fz18.bold.mt-2.text-center(v-if="isShowLoading") 決済リンク生成中
          p.fz18.bold.mt-2.text-center(v-else) 決済ページへ
          div.ma-2
            MinNowLoading.mb-4(:show="isShowLoading")
            div.f.fh.mt-8.mb-8(v-if="!isShowLoading")
              v-btn(color="#E7305B")
                span.bold(style="color:#fff;" @click="toPayment") PayPayでお支払い
        div(v-if="dialogType=='completePayment'")
          p.fz18.bold.mt-2.text-center お支払いを確認しました！
          div.ma-2
            div.px-2
              p.text-center.mb-0 マッチング品質向上のため、
              p.text-center ワーカーへのレビューのご協力をお願いします！
            div.f.fh.mt-6.mb-2
              v-btn(color="#E7305B")
                span.bold(style="color:#fff;" @click="toReview") レビューへ
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-myorder {
  width: 100%;
  min-height: 100vh;
  max-width: $base_max_width_px;
  position: fixed;

  .order-title {
    position: fixed;
    width: 100%;
    max-width: $base_max_width_px;
    top: 48px;
    background-color: $body_main;
    font-weight: bold;
    color: #fff;

    .span-left {
      position: relative;
      top: 3px;
    }

    .span-right {
      position: relative;
      top: 5px;
    }
  }

  .message-loader {
    position: fixed;
    z-index: 1;
    top: 91px;
    margin-bottom: 48px;
    height: calc(100vh - 139px);
    width: $base_max_width_px;
    background-color: #fff;
  }

  .message-notification {
    position: fixed;
    left: calc(50% - 140px);
    z-index: 2;
    top: 120px;
    height: 32px;
    line-height: 32px;
    width: 280px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background-color: rgba(100, 99, 99, 0.60);
  }

  #message-container {
    position: relative;
    top: 44px;
    overflow-y: scroll;

    img {
      border-radius: 2%;
      max-width: 240px;
      max-height: 240px;
    }

    .my-message {
      margin-left: 10%;

      .my-text {
        overflow-wrap: normal;
        position: relative;
        display: inline-block;
        margin: 0.5em 15px 0.5em 0;
        padding: 7px 10px;
        // min-width: 120px;
        max-width: 90%;
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
        background: $body_main;
      }
      .my-text:before {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 100%;
        margin-top: -13px;
        border: 5px solid transparent;
        border-left: 10px solid $body_main;
      }
      .my-text p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }

    .other-message {
      max-width: 90%;

      .other-text {
        overflow-wrap: normal;
        position: relative;
        display: inline-block;
        margin: 0.5em 0 0.5em 15px;
        border-radius: 8px;
        padding: 7px 10px;
        max-width: 100%;
        font-size: 16px;
        background: $body_spotwork;
      }
      .other-text:before {
        content: "";
        position: absolute;
        bottom: 10px;
        left: -15px;
        margin-top: -13px;
        border: 5px solid transparent;
        border-right: 10px solid $body_spotwork;
      }
      .other-text p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }

    .system-message {

      .system-text {
        overflow-wrap: normal;
        position: relative;
        display: inline-block;
        margin: 0.5em 0 0.5em 15px;
        border-radius: 8px;
        padding: 16px 16px;
        max-width: 100%;
        font-size: 16px;
        background: $body_secondary;
        opacity: 0.6;
      }

      .system-text p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }

    .timestamp {
      color: $text_secondary;
    }
  }

  .input-container {
    width: 100%;
    max-width: $base_max_width_px;
    background-color: $body_main;
    position: fixed;

    .input-left {
      width: 56px;
    }

    .input-right {
      width: 56px;
    }

    .input-center {
      width: 100%;
      max-width: 416px;

      textarea {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 4px 8px;
        background-color: #fff;
        width: 100%;
        border-radius: 4px;
      }
    }
  }

  .actions-container {
    background-color: $body_main;
    height: 96px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    max-width: $base_max_width_px;

    .action-content {
      cursor: pointer;

      p {
        color: #fff;
      }
    }
  }

  .action-btn {
    position: fixed;
    bottom: 100px;
    right: calc(50% - 150px);
  }
}

.schedule-container {
  min-width: 334px;

  .candidate-text {
    color: $text_main;
    min-width: 154px
  }

  .timepicker {
    p {
      font-size: 48px
    }

    select {
      height: 56px;
      width: 108px;
      font-size: 40px;
      color: $text_main;
      border-radius: 4px;
      text-align: center;
      font-weight: bold;
      border: 2px solid #b7b7b7;
    }
  }
}

.point {
    span {
      color: $text_sub;
    }

    input {
      border-radius: 8px;
      border: solid 2px $border_color;
      padding: 4px 16px;
      font-size: 24px;
      color: $text_sub;
      font-weight: bold;
      width: 200px;
    }

    .v-icon {
      cursor: pointer;
    }
  }


.stripe-wrapper {
  min-width: 300px;
}

.cash-wrapper {
  min-width: 300px;
}

.report-container {
  min-width: 300px;
}

</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import { functions, storage } from '@/components/utils/firebase'
import { database } from '../../../functions/shared/database'
import MinNowLoading from '@/components/shared/MinNowLoading'
import ItemStripeCardElement from '@/components/item/ItemStripeCardElement'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    MinNowLoading,
    ItemStripeCardElement
  },
  computed: {
    ...mapStateAuth(['uid']),
    displayCategoryIcon () {
      switch (this.order.category) {
        case '買物代行':
          return 'mdi-cart'
        case 'その他':
          return 'mdi-briefcase'
      }
    },
    displayScheduleDate: () => (date) => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 >= 10) ? date.getMonth() + 1 : '0' + String(date.getMonth() + 1)
      const day = (date.getDate() >= 10) ? date.getDate() : '0' + String(date.getDate())
      const hour = (date.getHours() >= 10) ? date.getHours() : '0' + String(date.getHours())
      const minute = (date.getMinutes() >= 10) ? date.getMinutes() : '0' + String(date.getMinutes())
      return `${year}/${month}/${day} ${hour}:${minute}`
    },
    showCreatedAt: () => (timestamp) => {
      const date = timestamp.toDate()
      let hour = date.getHours()
      let minutes = date.getMinutes()
      if (hour < 10) {
        hour = '0' + String(hour)
      }
      if (minutes < 10) {
        minutes = '0' + String(minutes)
      }
      return `${hour}:${minutes}`
    }
  },
  watch: {
    inputText: {
      handler: function (val) {
        const regexp = /\S/
        if (val !== '' && regexp.test(val)) {
          this.canSendText = true
        } else {
          this.canSendText = false
        }

        let lines = 0
        if (val !== '' && val.match(/\r\n|\n/g) !== null) {
          lines = val.match(/\r\n|\n/g).length
          if (lines === 1) {
            this.inputContainerClass.height = '80px'
            this.textareaClass.height = '64px'
          } else if (lines >= 2) {
            this.inputContainerClass.height = '112px'
            this.textareaClass.height = '96px'
          }
        } else {
          this.inputContainerClass.height = '48px'
          this.textareaClass.height = '32px'
        }
      },
      immediate: true
    },
    isInputting: function (val) {
      if (val) {
        this.isOpenActions = false
      }
    },
    isOpenActions: function (val) {
      if (val) {
        this.inputContainerClass.bottom = '96px'
        this.messageContainerClass = {
          height: 'calc(100vh - 236px)',
          marginBottom: '144px'
        }
      } else {
        this.inputContainerClass.bottom = '0px'
        this.messageContainerClass = {
          height: 'calc(100vh - 139px)',
          marginBottom: '48px'
        }
      }
    },
    usedPoint: function (val) {
      this.order.usedPoint = val
      if (this.order.servicePaymentCoupon) {
        this.canPayWithoutTransaction = this.order.serviceFee + this.order.agencyFee + Math.floor(this.order.advancedMoney) + Number(this.chip) - this.order.servicePaymentCoupon - val <= 0
      } else {
        this.canPayWithoutTransaction = this.order.serviceFee + this.order.agencyFee + Math.floor(this.order.advancedMoney) + Number(this.chip) - val <= 0
      }
    }
  },
  data () {
    return {
      order: null,
      work: null,
      messages: [],
      inputText: '',
      inputImage: null,
      hours: [],
      selectedHour: '00',
      minutes: [],
      selectedMinute: '00',
      candidateDates: [],
      editedDateIndex: null,
      inputDate: null,
      inputTime: null,
      stringCreatedAt: '',
      stringScheduledDate: null,
      inputContainerClass: {
        height: '48px',
        bottom: '0px'
      },
      textareaClass: {
        height: '32px'
      },
      messageContainerClass: {
        height: 'calc(100vh - 139px)',
        marginBottom: '48px'
      },
      isInputting: false,
      canSendText: false,
      isLoading: true,
      isShowLoading: false,
      messageContainer: null,
      hasNewMessage: false,
      isShowDialog: false,
      dialogType: '',
      isOpenActions: false,
      chip: 0,
      imgsIndex: 0,
      paymentUrl: null,
      point: 0,
      usedPoint: null,
      inputPoint: 0,
      canPayWithoutTransaction: false
    }
  },
  async mounted () {
    database.orderCollection().getOrderSnapByWorkId(this.$route.params.workId, (orders) => {
      console.log(orders)
      this.order = orders[0]
      if (!this.order) {
        this.$router.push('/mypage')
      }
    })

    const unsubscribeUpdateMessages = database
      .messageCollection(this.$route.params.workId)
      .onUpdatedWithOptions(this.updateMessages)
    await this.setUnsubscribeUpdateMessages(unsubscribeUpdateMessages)

    await database.messageRoomCollection().update(this.$route.params.workId, {
      customerUnReadMessageCount: 0
    })

    this.hours = [...Array(24).keys()].map(number => {
      if (number < 10) {
        return '0' + String(number)
      } else {
        return String(number)
      }
    })
    this.minutes = [...Array(60).keys()].map(number => {
      if (number < 10) {
        return '0' + String(number)
      } else {
        return String(number)
      }
    })

    const createdAt = this.order.createdAt.toDate()
    this.stringCreatedAt = `${createdAt.getMonth() + 1}月${createdAt.getDate()}日`
    if (this.order.scheduledDate) {
      const date = this.order.scheduledDate.toDate()
      this.stringScheduledDate = `${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours()}時${date.getMinutes()}分`
    }

    this.messageContainer = document.getElementById('message-container')
    setTimeout(this.scrollDown, 600)

    if (this.order.status === 'delivered') {
      this.work = await database.workCollection().findById(this.$route.params.workId)
      if (this.order.chip) {
        this.chip = this.order.chip
      }
      this.dialogType = 'report'
    }

    if (!this.order.payOnlyCash) {
      this.point = await database.userCollection().findById(this.uid).then(user => {
        if (user.point && user.point > 0) {
          return user.point
        } else {
          return 0
        }
      })
      this.usedPoint = 0
    }
  },
  methods: {
    ...mapActionsAuth(['setUnsubscribeUpdateMessages']),
    displayTotalAmount () {
      if (this.order.payOnlyCash) {
        return (this.order.advancedMoney + Number(this.order.chip))
      } else {
        if (this.order.servicePaymentCoupon) {
          return (this.order.serviceFee - this.order.servicePaymentCoupon) + this.order.agencyFee + Math.floor(this.order.advancedMoney * 1.09) + Number(this.order.chip) - this.usedPoint
        } else {
          return this.order.serviceFee + this.order.agencyFee + Math.floor(this.order.advancedMoney * 1.09) + Number(this.order.chip) - this.usedPoint
        }
      }
    },
    displayCommission () {
      return Math.floor(this.order.advancedMoney * 0.09)
    },
    async updateMessages (messages) {
      this.messages = messages
      if (!this.isLoading) {
        const sentBy = this.messages[this.messages.length - 1].createdBy
        const targetHeight = this.messageContainer.scrollHeight - document.documentElement.clientHeight * 1.5
        if (this.messageContainer.scrollTop < targetHeight) {
          if (sentBy !== this.uid) {
            this.hasNewMessage = true
            setTimeout(this.hideMessageNotification, 2000)
          }
        } else {
          setTimeout(this.scrollDown, 200)
        }
        if (sentBy !== this.uid) {
          setTimeout(async () => {
            await database.messageRoomCollection().update(this.$route.params.workId, {
              customerUnReadMessageCount: 0
            })
          }, 2000)
        }
      }
    },
    scrollDown () {
      this.messageContainer.scrollTop = this.messageContainer.scrollHeight
      this.isLoading = false
    },
    hideMessageNotification () {
      this.hasNewMessage = false
    },
    async sendMessage () {
      if (this.canSendText) {
        const data = {
          createdBy: this.uid,
          type: 'text',
          content: this.inputText,
          createdAt: new Date(),
          hasRead: false
        }
        await database.messageCollection(this.order.workId).add(data)
        this.inputText = ''
      }
    },
    selectImage () {
      this.$refs.inputImage.click()
    },
    // 以下画像関係
    async setImage (e) {
      const files = e.target.files || e.dataTransfer.files
      this.createImage(files[0])
    },
    createImage (file) {
      // 画像リサイズ後の最大値の幅
      const THUMBNAIL_WIDTH = 240
      const THUMBNAIL_HEIGHT = 240

      const imgCanvas = this.$refs.imgCanvas

      const image = new Image()
      const reader = new FileReader()
      reader.onload = (e) => {
        image.onload = () => {
          var width, height, ratio
          if (image.width > image.height) {
            // 横長の画像は横のサイズを指定値にあわせる
            ratio = image.height / image.width
            width = THUMBNAIL_WIDTH
            height = THUMBNAIL_WIDTH * ratio
          } else {
            // 縦長の画像は縦のサイズを指定値にあわせる
            ratio = image.width / image.height
            width = THUMBNAIL_HEIGHT * ratio
            height = THUMBNAIL_HEIGHT
          }

          imgCanvas.setAttribute('width', width)
          imgCanvas.setAttribute('height', height)

          const ctx = imgCanvas.getContext('2d')

          // canvasに既に描画されている画像をクリア
          ctx.clearRect(0, 0, width, height)
          // canvasにサムネイルを描画
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height)
        }
        // image.onload = this.createBase64
        image.src = e.target.result
        const result = window.confirm('選択した画像を送信してもよろしいですか?')
        if (result) {
          setTimeout(this.sendImage, 400)
        } else {
          const ctx = imgCanvas.getContext('2d')
          ctx.clearRect(0, 0, 0, 0)
        }
      }
      reader.readAsDataURL(file)
    },
    async sendImage () {
      const base64 = this.$refs.imgCanvas.toDataURL()
      const docId = database.messageCollection(this.order.workId).getNewDocId()
      // ストレージオブジェクト作成
      const storageRef = storage.ref()
      const imgRef = storageRef.child(`MESSAGE/${this.order.workId}/${docId}.jpg`)

      // ファイルを適用してファイルアップロード開始
      await imgRef.putString(base64.split(',')[1], 'base64').then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
          const data = {
            createdBy: this.uid,
            type: 'image',
            content: downloadURL,
            createdAt: new Date(),
            hasRead: false
          }
          await database.messageCollection(this.order.workId).set(docId, data)
          this.inputImage = null
        })
      })
    },
    showScheduleDialog () {
      this.isShowDialog = true
      this.dialogType = 'schedule'
    },
    showDatePicker (index) {
      this.editedDateIndex = index
      this.dialogType = 'datePicker'
    },
    decideDate () {
      this.dialogType = 'timePicker'
    },
    decideTime () {
      const date = this.inputDate.replace(/-/g, '/')
      const candidateDate = new Date(`${date} ${this.selectedHour}:${this.selectedMinute}:00`)
      this.candidateDates.splice(this.editedDateIndex, 1, candidateDate)
      this.dialogType = 'schedule'
    },
    popCandidateDates () {
      this.candidateDates.splice((this.candidateDates.length - 1), 1)
    },
    async decideCandidateDate () {
      if (this.candidateDates.length > 0) {
        await database.orderCollection().update(this.order.id, {
          candidateDates: this.candidateDates
        })
      }
      this.isShowDialog = false
      this.dialogType = ''
      this.isOpenActions = false
    },
    confirmWork () {
      this.dialogType = 'chip'
    },
    async confirmChip () {
      if (isNaN(Number(this.chip))) {
        this.chip = 0
      }
      await database.orderCollection().update(this.order.id, {
        chip: Number(this.chip)
      })

      this.order.chip = Number(this.chip)

      this.dialogType = 'selectPaymentMethods'
    },
    async toPayment () {
      // chipを入力するようになったら削除する
      await database.orderCollection().update(this.order.id, {
        chip: Number(this.chip)
      })
      this.order.chip = Number(this.chip)

      if (this.order.payOnlyCash) {
        this.dialogType = 'cashPayment'
      } else {
        this.dialogType = 'stripePayment'
      }
    },
    decidePoint () {
      let point = 0
      let totalAmount = this.order.serviceFee + this.order.agencyFee
      if (this.order.toUseCoupon) totalAmount -= this.couponAmount
      if (!this.order.payOnlyCash) totalAmount += Math.floor(this.order.budget * 1.09)

      if (this.inputPoint < 0) {
        this.usedPoint = 0
        this.inputPoint = 0
      }

      if (this.point < this.inputPoint) {
        point = this.point
      } else {
        point = this.inputPoint
      }

      if (point > totalAmount) {
        point = totalAmount
      }
      this.usedPoint = point
      this.inputPoint = 0
    },
    cancelPoint () {
      this.usedPoint = 0
      this.inputPoint = 0
    },
    async cashPayment () {
      const req = {
        orderId: this.order.id,
        type: 'cash'
      }
      const createPayment = functions.httpsCallable('createStripePayment')
      await createPayment({ req }).then(async (res) => {
        if (res.data.status === 'success') {
          await database.orderCollection().update(this.order.id, {
            advancedPaymentType: 'cash',
            status: 'done',
            endedAt: new Date()
          })
          this.dialogType = 'completePayment'
        } else {
          window.alert('確認に失敗しました。お手数ですがもう一度やり直してください。')
        }
      })
    },
    async afterStripePayment (intentId) {
      if (intentId.paymentIntentId === 'lessThanPay') {
        this.dialogType = 'chip'
        return
      }

      if (this.canPayWithoutTransaction) {
        await database.orderCollection().update(this.order.id, {
          advancedPaymentType: 'point',
          usedCoupon: this.usedCoupon,
          status: 'done',
          endedAt: new Date()
        })
      } else {
        await database.orderCollection().update(this.order.id, {
          advancedPaymentType: 'stripe',
          advancedPaymentId: intentId,
          status: 'done',
          endedAt: new Date()
        })
      }
      this.dialogType = 'completePayment'
    },
    async createPayPayPayment () {
      this.isShowLoading = true

      const userAgent = navigator.userAgent
      const req = {
        orderId: this.order.id,
        type: 'advancedPayment',
        merchantPaymentId: this.work.id,
        amount: this.order.advancedMoney + Number(this.chip),
        orderDescription: (Number(this.chip) > 0) ? '立て替え料金 + チップ' : '立て替え料金',
        userAgent: userAgent
      }

      let expiredAt
      if (this.order.advancedPayment) {
        expiredAt = this.order.advancedPayment.expiredAt.toDate()
        if (expiredAt.getTime() - 60000 >= new Date().getTime()) {
          this.paymentUrl = this.order.advancedPayment.url
          this.isShowLoading = false
        } else {
          const reCreateQRCode = functions.httpsCallable('reCreatePayPayQRCode')
          reCreateQRCode({ req }).then((res) => {
            const status = res.data.status
            if (status === 201) {
              const result = res.data.body.data
              this.paymentUrl = result.url
              this.isShowLoading = false
            }
          })
        }
      } else {
        const createQRCode = functions.httpsCallable('createPayPayQRCode')
        createQRCode({ req }).then((res) => {
          const status = res.data.status
          if (status === 201) {
            const result = res.data.body.data
            this.paymentUrl = result.url
            this.isShowLoading = false
          }
        })
      }
    },
    // toPayment () {
    //   this.isShowDialog = false
    //   window.location.href = this.paymentUrl
    // },
    toReview () {
      this.isShowDialog = false
      this.$router.push(`/mypage/order/${this.$route.params.workId}/review`)
    }
  }
}

</script>

<template lang="pug">
  div.wrap-module-order-review.fc.f
    div.order-review
      div.order-title
        p.ml-3.pt-1.mb-1.bold.fz18 {{order.category}}
        p.ml-3.mb-2.bold.fz14 {{stringCreatedAt}}
      p.bold.mt-10 評価
      div.f.fc.mb-4
        v-rating(
          background-color="grey lighten-2"
          color="yellow"
          hover
          length="5"
          size="32"
          v-model="reviewScore")
      div
        p.bold 本文
        v-textarea.textarea(v-model="reviewText" outlined)
      div.f.fc
        v-btn(color="#E7305B" @click="sendReview")
          span.bold(style="color:#fff;") レビューする
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-order-review {
  margin: 0 auto;
  width: 100%;
  max-width: $base_max_width_px;
  min-height: 100vh;

  .order-review {
    width: 100%;
    max-width: $base_max_width_px;

    .order-title {
      background-color: $body_main;

      p {
        color: #fff;
      }
    }

    .textarea {
      margin: 0 5%;
    }
  }
}

</style>

<script>
import { database } from '../../../functions/shared/database'
import { createNamespacedHelpers } from 'vuex'
import router from '@/router'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  data () {
    return {
      reviewScore: 3,
      reviewText: '',
      order: null,
      stringCreatedAt: ''
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async mounted () {
    const orders = await database.orderCollection().getOrderByWorkId(this.$route.params.workId)
    this.order = orders[0]
    const date = this.order.createdAt.toDate()
    this.stringCreatedAt = date.getFullYear() + '年' + `${date.getMonth() + 1}` + '月' + date.getDate() + '日 ' +
        `${(date.getHours() < 10) ? '0' + date.getHours() : date.getHours()}` + ':' + `${(date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes()}`
  },
  methods: {
    async sendReview () {
      await database.workCollection().update(this.$route.params.workId, {
        review: {
          score: Number(this.reviewScore),
          text: this.reviewText,
          reviewedBy: this.uid,
          createdAt: new Date()
        }
      })

      await database.orderCollection().update(this.order.id, {
        reviewedAt: new Date()
      })

      this.$router.push('/mypage')
    }
  }
}
</script>
